import React from 'react';

export class Arrow extends React.Component {
  render() {
    return (
      <svg
        viewBox='-1 -1 36 36'
        xmlns='http://www.w3.org/2000/svg'
        className={this.props.classProps}
      >
        <g fill='none' fillRule='evenodd'>
          <ellipse
            className='stroke'
            stroke='#FFF'
            strokeWidth='2'
            transform='matrix(1 0 0 -1 0 33.841)'
            cx='17'
            cy='16.921'
            rx='17'
            ry='16.921'
          />
          <path
            className='stroke'
            stroke='#FEFEFE'
            strokeWidth='3'
            transform='matrix(-1 0 0 1 34 0)'
            d='M14 10.841L20 17.341 14 23.841'
          />
        </g>
      </svg>
    );
  }
}

export class ArrowDouble extends React.Component {
  render() {
    return (
      <svg viewBox='0 0 8 6' version='1.1' xmlns='http://www.w3.org/2000/svg'>
          <g
            className='fill'
            transform='translate(-132.000000, -816.000000)'
            fill='#29CB7E'
            fillRule='nonzero'
          >
            <path
              d='M132.504,818.932 L135.42,816.016 L136.296,816.88 L134.196,818.968 L136.272,821.056 L135.456,821.872 L132.504,818.932 Z M136.264,818.932 L139.18,816.016 L140.056,816.88 L137.956,818.968 L140.032,821.056 L139.216,821.872 L136.264,818.932 Z'
            />
          </g>
      </svg>
    );
  }
}

export class ArrowSmaller extends React.Component {
  render() {
    return (
      <svg
        viewBox='0 0 9 14'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        className={this.props.classProps}
      >
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g
            className='fill'
            transform='translate(-65.000000, -4052.000000)'
            fill='#29CB7E'
            fillRule='nonzero'
          >
            <polygon points='65.132 4059.186 71.45 4052.868 73.348 4054.74 68.798 4059.264 73.296 4063.788 71.528 4065.556' />
          </g>
        </g>
      </svg>
    );
  }
}

export class HerbamojoLogo extends React.Component {
  render() {
    return (
      <svg enableBackground='new 0 0 492 201' viewBox='0 0 492 201'>
        <g
          clipRule='evenodd'
          fill='#29cb7e'
          className='fill'
          fillRule='evenodd'
        >
          <path
            d='m28.7 0c1 0 1.8.3 2.5 1s1 1.5 1 2.4v28.8h29.2v-28.7c0-.9.3-1.7 1-2.4.8-.7 1.6-1.1 2.6-1.1h25.2c1 0 1.8.3 2.5 1s1 1.5 1 2.4v83.1c0 .9-.3 1.7-1 2.4s-1.5 1-2.5 1h-25.2c-1 0-1.8-.3-2.5-1s-1-1.5-1-2.4v-30.6h-29.3v30.7c0 .9-.3 1.7-1 2.4s-1.5 1-2.5 1h-25.3c-.9 0-1.7-.3-2.4-1s-1-1.5-1-2.4v-83.1c0-.9.3-1.7 1-2.4s1.5-1 2.4-1h25.3z'
            mask='url(#a)'
          />
          <path
            d='m104.8 90c-.9 0-1.7-.3-2.4-1s-1-1.5-1-2.4v-83.1c0-.9.3-1.7 1-2.4s1.5-1 2.4-1h66.9c.9 0 1.7.3 2.4 1s1 1.5 1 2.4v16.8c0 1-.3 1.8-1 2.5s-1.5 1-2.4 1h-38.5v9.2h32.9c.9 0 1.7.3 2.4 1s1 1.5 1 2.4v16.6c0 1-.3 1.8-1 2.5s-1.5 1-2.4 1l-32.9.1v9.6h41.6c1 0 1.8.3 2.5 1s1 1.5 1 2.5v16.8c0 .9-.3 1.7-1 2.4-.6.7-1.5 1-2.5 1h-70z'
            mask='url(#b)'
          />
          <path d='m288.3 84.8-15.7-26.5c-1.1-1.5-2.6-3.1-4.3-4.6s-3.8-3-6.2-4.2c2.1-.7 4-1.5 5.7-2.4s3.2-1.8 4.5-2.8c5.3-3.9 7.9-10.2 7.9-18.9 0-7.7-3.2-14-9.6-18.7-6-4.4-13.9-6.5-23.7-6.5h-57.6c-.9 0-1.7.3-2.4 1s-1 1.5-1 2.4v83.1c0 .9.3 1.7 1 2.4s1.5 1 2.4 1h25.5c1 0 1.8-.3 2.5-1s1-1.5 1-2.4v-28.5h11.1c4.1 0 6.5.2 7.1.7.4.2.8.5 1.1.9s.7.8 1 1.1l-.1-.2 14.7 27.3c.3.5.7 1 1.3 1.4s1.2.6 1.8.6h29.1c1.5 0 2.5-.6 3.2-1.7.5-1 .5-2.2-.3-3.5zm-44.3-49.1c-2.9 1.3-7.2 1.9-12.7 1.9h-13.8v-17h17.9c4.2 0 7.4.6 9.6 2 2.2 1.3 3.3 3.4 3.3 6.2.1 3.3-1.4 5.6-4.3 6.9z' />
          <path d='m322.8 52.4v17.1h7.9c3.2 0 5.9-.8 8-2.3s3.1-3.6 3.1-6.1c0-2.7-1-4.8-3-6.4s-4.6-2.4-7.8-2.4h-8.2zm0-31.8v14.3h7c2.9 0 5.3-.7 7.1-2.2s2.7-3.1 2.7-4.8c0-2-.9-3.7-2.6-5.2s-4.1-2.2-7.1-2.2h-7.1zm-27.1 69.4c-.9 0-1.7-.3-2.4-1s-1-1.5-1-2.4v-83.1c0-.9.3-1.7 1-2.4s1.5-1 2.4-1h46.3c4 0 7.6.5 10.8 1.5s6.2 2.5 8.8 4.5c2.7 2.1 4.7 4.5 6.1 7.2 1.4 2.6 2.1 5.7 2.1 9.1 0 4.2-.9 7.9-2.8 11.1-1.8 3.2-4.7 5.8-8.6 7.8 4 1.6 7 3.6 9.1 6 3.8 4.4 5.7 10.1 5.7 17.1 0 7.7-2.9 14-8.8 18.8-5.6 4.5-12.8 6.8-21.8 6.8z' />
          <path d='m488.9 85-44.4-83c-.3-.5-.8-1-1.4-1.4s-1.2-.6-1.7-.6h-20.3c-.5 0-1 .2-1.7.6s-1.1.8-1.4 1.3l-47.1 83c-.8 1.3-.8 2.5-.1 3.6s1.8 1.6 3.3 1.6h23.7c.6 0 1.2-.2 1.8-.6s1.1-.8 1.4-1.4l5.8-11.2h43l5.8 11.2c.2.5.7 1 1.3 1.4s1.2.6 1.8.6h26.8c1.5 0 2.6-.5 3.2-1.6.8-1.1.8-2.2.2-3.5zm-70.8-28.4 11-20 10.3 20z' />
          <path d='m140.9 96.5c1.1 0 2.1.4 2.9 1.2s1.2 1.7 1.2 2.8v94.9c0 1.1-.4 2-1.2 2.8s-1.8 1.2-2.9 1.2h-26.2c-1.1 0-2.1-.4-2.9-1.2s-1.2-1.7-1.2-2.8v-51.2l-28.3 52.8c-.3.6-.9 1.1-1.6 1.6-.7.4-1.4.7-2 .7h-12.2c-.6 0-1.3-.2-2-.7-.7-.4-1.2-1-1.6-1.6l-28.3-52.8v51.1c0 1.1-.4 2-1.2 2.8s-1.8 1.2-2.9 1.2h-26.1c-1.1 0-2.1-.4-2.9-1.2s-1.2-1.7-1.2-2.8v-94.9c0-1 .4-2 1.2-2.8s1.7-1.2 2.9-1.2h30.6c.5 0 1.2.2 1.9.6s1.2.9 1.5 1.4l34.3 55.7 33.9-55.7c.3-.5.9-1 1.6-1.4s1.3-.6 2-.6h30.7z' />
          <path d='m233.7 130.8c-4.3-3.8-10.6-5.7-18.8-5.7s-14.4 1.9-18.7 5.7-6.4 9.5-6.4 17 2.1 13.2 6.4 17 10.5 5.7 18.7 5.7 14.5-1.9 18.8-5.7 6.5-9.5 6.5-17c0-7.6-2.2-13.2-6.5-17m27.1 56.9c-10.4 8.7-25.7 13.1-45.9 13.1s-35.6-4.4-45.9-13.1c-10.4-8.7-15.6-22.1-15.6-40s5.2-31.3 15.6-40c10.4-8.8 25.7-13.2 45.9-13.2s35.6 4.4 45.9 13.2c10.4 8.8 15.6 22.1 15.6 40s-5.2 31.3-15.6 40' />
          <path d='m359.5 97.4c-.7-.8-1.6-1.2-2.7-1.2h-28c-1 0-2 .4-2.8 1.2s-1.2 1.7-1.2 2.9v55.4c0 6.9-3.5 10.6-10.3 11.1h-26.5c-1.4 0-2.5.5-3.4 1.4s-1.3 2.1-1.3 3.5v23.1c0 1.3.5 2.4 1.3 3.3.9 1 2 1.4 3.4 1.4h33.6v.1c11.4 0 20.8-1.6 28-8.2 7.3-6.9 11-15.7 11-26.3v-64.8c0-1.1-.4-2.1-1.1-2.9' />
          <path d='m449.3 130.8c-4.3-3.8-10.6-5.7-18.8-5.7s-14.4 1.9-18.7 5.7-6.4 9.5-6.4 17 2.1 13.2 6.4 17 10.5 5.7 18.7 5.7 14.5-1.9 18.8-5.7 6.5-9.5 6.5-17c-.1-7.6-2.2-13.2-6.5-17m27.1 56.9c-10.4 8.7-25.7 13.1-45.9 13.1s-35.6-4.4-45.9-13.1c-10.4-8.7-15.6-22.1-15.6-40s5.2-31.3 15.6-40c10.4-8.8 25.7-13.2 45.9-13.2s35.6 4.4 45.9 13.2c10.4 8.8 15.6 22.1 15.6 40s-5.2 31.3-15.6 40' />
        </g>
      </svg>
    );
  }
}
