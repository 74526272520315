import React from 'react';

export default (props) => (
	<svg viewBox="0 0 70 68" version="1.1" xmlns="http://www.w3.org/2000/svg">
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-1185.000000, -6196.000000)">
				<g transform="translate(-40.000000, 6118.000000)">
					<g transform="translate(1227.000000, 80.000000)">
						<ellipse
							className="stroke"
							stroke="#000000"
							strokeWidth="3"
							cx="32.9275609"
							cy="32.2366215"
							rx="32.9275609"
							ry="32.2366215"
						/>
						<g className="fill" transform="translate(14.400000, 13.579214)" fill="#000000">
							<path
								d="M18.591816,34.0349587 C21.367432,33.9629826 24.1352773,33.924341 26.8999813,33.8064262 C28.4043493,33.7420788 29.833656,33.3321134 31.0604293,32.3954286 C32.8238747,31.0489443 33.566552,29.1550101 33.625576,27.034036 C33.7434587,22.809999 33.7671013,18.5824793 33.769912,14.3564521 C33.7710693,12.7237216 33.705928,11.0788846 33.501576,9.46091424 C33.1272613,6.49446938 31.2821413,4.52308626 28.3770693,3.88044222 C27.5161787,3.69005374 26.6192453,3.59950313 25.736696,3.58358214 C22.246344,3.52056156 18.755,3.4754521 15.2643173,3.48473934 C13.448792,3.48954881 11.628472,3.5598665 9.81956,3.71061835 C6.665,3.97348049 4.48640267,5.86774634 3.80737867,8.90019695 C3.61426933,9.76274963 3.52036,10.6624513 3.50432267,11.5473928 C3.44066933,15.069248 3.39636,18.5919323 3.40495733,22.114285 C3.409256,23.9146834 3.47704267,25.7202229 3.630968,27.51349 C3.81035467,29.6031196 4.64842933,31.3897529 6.44213067,32.6045574 C7.60310133,33.3909879 8.92345333,33.7500393 10.2988613,33.8064262 C13.063896,33.9196974 15.831576,33.9629826 18.591816,34.0349587 M0.029512,25.5974986 L0.029512,11.9153998 C0.113501333,11.0543398 0.185752,10.1919529 0.283298667,9.33255128 C0.813522667,4.66305827 3.94510133,1.29643276 8.566664,0.473516708 C9.63604,0.28312823 10.7280667,0.220439342 11.809512,0.0990418107 L25.449512,0.0990418107 C25.918232,0.143653745 26.3861253,0.197884609 26.855672,0.231053333 C29.8685413,0.443499012 32.5490907,1.38681753 34.5805413,3.75821547 C36.381848,5.86127844 37.018712,8.38724263 37.0783973,11.0702607 C37.165032,14.964269 37.2010747,18.8604332 37.1751173,22.7551048 C37.1622213,24.7185274 37.128824,26.7069924 36.818824,28.6380756 C36.1336827,32.9044027 33.6788133,35.7434797 29.447768,36.791943 C27.962744,37.1597842 26.384968,37.2818451 24.848856,37.286157 C19.7701467,37.3009171 14.6846587,37.3534895 9.614216,37.1198159 C4.763832,36.8960928 1.26372533,33.5795521 0.402173333,28.7941344 C0.212370667,27.7405299 0.150370667,26.6637072 0.029512,25.5974986"
							/>
							<path
								d="M24.7646848,19.0045679 C25.0103701,15.7745975 22.3885141,12.8144547 19.0481195,12.5502658 C15.6845781,12.2840868 12.6970048,14.8860074 12.4422261,18.3037127 C12.1859595,21.7385 14.7654901,24.6382757 18.3183381,24.9099275 C21.6255008,25.1626732 24.4963488,22.5327251 24.7646848,19.0045679 M28.1665835,18.7911271 C28.1447595,24.0533452 23.8398101,28.3337691 18.5800608,28.3229893 C13.2915435,28.3123753 9.04214613,24.0049189 9.0618208,18.6752024 C9.08133013,13.3826349 13.3376715,9.15080325 18.6328021,9.15926128 C23.9290901,9.1677193 28.1885728,13.4710296 28.1665835,18.7911271 Z"
							/>
						</g>
						<g className="fill" transform="translate(14.400000, 13.579214)" fill="#000000">
							<path d="M18.591816,34.0349587 C21.367432,33.9629826 24.1352773,33.924341 26.8999813,33.8064262 C28.4043493,33.7420788 29.833656,33.3321134 31.0604293,32.3954286 C32.8238747,31.0489443 33.566552,29.1550101 33.625576,27.034036 C33.7434587,22.809999 33.7671013,18.5824793 33.769912,14.3564521 C33.7710693,12.7237216 33.705928,11.0788846 33.501576,9.46091424 C33.1272613,6.49446938 31.2821413,4.52308626 28.3770693,3.88044222 C27.5161787,3.69005374 26.6192453,3.59950313 25.736696,3.58358214 C22.246344,3.52056156 18.755,3.4754521 15.2643173,3.48473934 C13.448792,3.48954881 11.628472,3.5598665 9.81956,3.71061835 C6.665,3.97348049 4.48640267,5.86774634 3.80737867,8.90019695 C3.61426933,9.76274963 3.52036,10.6624513 3.50432267,11.5473928 C3.44066933,15.069248 3.39636,18.5919323 3.40495733,22.114285 C3.409256,23.9146834 3.47704267,25.7202229 3.630968,27.51349 C3.81035467,29.6031196 4.64842933,31.3897529 6.44213067,32.6045574 C7.60310133,33.3909879 8.92345333,33.7500393 10.2988613,33.8064262 C13.063896,33.9196974 15.831576,33.9629826 18.591816,34.0349587 M0.029512,25.5974986 L0.029512,11.9153998 C0.113501333,11.0543398 0.185752,10.1919529 0.283298667,9.33255128 C0.813522667,4.66305827 3.94510133,1.29643276 8.566664,0.473516708 C9.63604,0.28312823 10.7280667,0.220439342 11.809512,0.0990418107 L25.449512,0.0990418107 C25.918232,0.143653745 26.3861253,0.197884609 26.855672,0.231053333 C29.8685413,0.443499012 32.5490907,1.38681753 34.5805413,3.75821547 C36.381848,5.86127844 37.018712,8.38724263 37.0783973,11.0702607 C37.165032,14.964269 37.2010747,18.8604332 37.1751173,22.7551048 C37.1622213,24.7185274 37.128824,26.7069924 36.818824,28.6380756 C36.1336827,32.9044027 33.6788133,35.7434797 29.447768,36.791943 C27.962744,37.1597842 26.384968,37.2818451 24.848856,37.286157 C19.7701467,37.3009171 14.6846587,37.3534895 9.614216,37.1198159 C4.763832,36.8960928 1.26372533,33.5795521 0.402173333,28.7941344 C0.212370667,27.7405299 0.150370667,26.6637072 0.029512,25.5974986" />
							<path d="M24.7646848,19.0045679 C25.0103701,15.7745975 22.3885141,12.8144547 19.0481195,12.5502658 C15.6845781,12.2840868 12.6970048,14.8860074 12.4422261,18.3037127 C12.1859595,21.7385 14.7654901,24.6382757 18.3183381,24.9099275 C21.6255008,25.1626732 24.4963488,22.5327251 24.7646848,19.0045679 M28.1665835,18.7911271 C28.1447595,24.0533452 23.8398101,28.3337691 18.5800608,28.3229893 C13.2915435,28.3123753 9.04214613,24.0049189 9.0618208,18.6752024 C9.08133013,13.3826349 13.3376715,9.15080325 18.6328021,9.15926128 C23.9290901,9.1677193 28.1885728,13.4710296 28.1665835,18.7911271 Z" />
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);
